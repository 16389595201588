import Gen from '@helper/Gen';
import BOGen from './helper/BOGen';

var bo_router = [{
        path: "dashboard",
        name: "BoDashboard",
        component: () =>
            import ("@backend/Dashboard.vue")
    },
    {
        path: "profile/:id?",
        name: "BoProfile",
        component: () =>
            import ("@backend/Profile.vue")
    },
    {
        path: "project-list/:id?",
        name: "MrProject",
        component: () =>
            import ("@backend/MrProject.vue")
    },
    {
        path: "project/home/:id?",
        name: "ProjectHome",
        component: () =>
            import ("@backend/ProjectHome.vue")
    },
    {
        path: "project/products/:id?",
        name: "ProjectProducts",
        component: () =>
            import ("@backend/ProjectProducts.vue")
    },
    {
        path: "project/about/:id?",
        name: "ProjectAbout",
        component: () =>
            import ("@backend/ProjectAbout.vue")
    },
    {
        path: "project/contact/:id?",
        name: "ProjectContact",
        component: () =>
            import ("@backend/ProjectContact.vue")
    },
    {
        path: "project/news/:id?",
        name: "ProjectNews",
        component: () =>
            import ("@backend/ProjectNews.vue")
    },
    {
        path: "project/progress/:id?",
        name: "ProjectProgress",
        component: () =>
            import ("@backend/ProjectProgress.vue")
    },
    {
        path: "price-list/:id?",
        name: "ProjectPriceList",
        component: () =>
            import("@backend/ProjectPriceList.vue")
    },
    {
        path: "snk-news/:id?",
        name: "SnkNews",
        component: () =>
            import("@backend/SnkNews.vue")
    },
    {
        path: "snk-home/:id?",
        name: "SnkHome",
        component: () =>
            import("@backend/SnkHome.vue")
    },
    {
        path: "snk-partner/:id?",
        name: "SnkPartner",
        component: () =>
            import("@backend/SnkPartner.vue")
    },
    {
        path: "snk-career/:id?",
        name: "SnkCareer",
        component: () =>
            import("@backend/SnkCareer.vue")
    },
    {
        path: "snk-about/:id?",
        name: "SnkAbout",
        component: () =>
            import("@backend/SnkAbout.vue")
    },
    {
        path: "snk-contact/:id?",
        name: "SnkContact",
        component: () =>
            import("@backend/SnkContact.vue")
    },
    {
        path: "contact/:id?",
        name: "Contact",
        component: () =>
            import("@backend/Contact.vue")
    },
    {
        path: "seo/:id?",
        name: "SettingSeo",
        component: () =>
            import("@backend/SettingSeo.vue")
    },
    {
        path: "legal-page/:id?",
        name: "SettingLegalPage",
        component: () =>
            import("@backend/SettingLegalPage.vue")
    },
    {
        path: "users/:id?",
        name: "BoUsers",
        component: () =>
            import ("@backend/Users.vue")
    },
    {
        path: "banner/:id?",
        name: "Banner",
        component: () =>
            import ("@backend/Banner.vue")
    },
    {
        path: "user-level/:id?",
        name: "BoUserLevel",
        component: () =>
            import ("@backend/UserLevel.vue")
    },
    { path: '*', redirect: { name: "BoDashboard" } },
];

var menuBypassRole = ["BoProfile"]

bo_router.map((v) => {
    v.beforeEnter = (to, from, next) => {
        var user = BOGen.user()
        if (!user || !Gen.getStorage('botk')) return next({ name: "BoLogin" })
        if (menuBypassRole.indexOf(to.name) > -1) return next()
        if (user.bu_level == 0) return next()
        if (user.menuRole.indexOf(to.name) == -1) return next({ name: user.menuRole[0] })
        next()
    }
    v.meta = { bo_protected: true }
})

var data = [{
        name: "BoLogin",
        path: "login",
        component: () =>
            import ("@backend/Login.vue"),
        beforeEnter: (to, from, next) => {
            var user = BOGen.user()
            if (user && Gen.getStorage('botk')) return next({ name: "BoDashboard" })
            next()
        }
    },
    // FileManager
    {
        name: "FileManager",
        path: 'file-explore',
        component: () =>
            import ("@components/FileManagerPage.vue")
    },
    {
        path: "",
        component: () =>
            import ('@/layout/BoLayout.vue'),
        redirect: { name: 'BoDashboard' },
        children: bo_router
    },
]

export default data